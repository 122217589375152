/* tslint:disable */
/* eslint-disable */
/**
 * Vista API
 * Open API definition
 *
 * The version of the OpenAPI document: 1.25.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../../base';
// @ts-ignore
import { BusinessUnit } from '../../model/vista';
// @ts-ignore
import { Pageable } from '../../model/vista';
/**
 * SbusApi - axios parameter creator
 * @export
 */
export const SbusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns related business units based on inputted business unit
         * @summary Returns a list of business units
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessUnits: async (searchTerm: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('getBusinessUnits', 'searchTerm', searchTerm)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getBusinessUnits', 'pageable', pageable)
            const localVarPath = `/sbus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SbusApi - functional programming interface
 * @export
 */
export const SbusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SbusApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns related business units based on inputted business unit
         * @summary Returns a list of business units
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessUnits(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BusinessUnit>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnits(searchTerm, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SbusApi.getBusinessUnits']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SbusApi - factory interface
 * @export
 */
export const SbusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SbusApiFp(configuration)
    return {
        /**
         * Returns related business units based on inputted business unit
         * @summary Returns a list of business units
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessUnits(searchTerm: string, pageable: Pageable, options?: any): AxiosPromise<Array<BusinessUnit>> {
            return localVarFp.getBusinessUnits(searchTerm, pageable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SbusApi - object-oriented interface
 * @export
 * @class SbusApi
 * @extends {BaseAPI}
 */
export class SbusApi extends BaseAPI {
    /**
     * Returns related business units based on inputted business unit
     * @summary Returns a list of business units
     * @param {string} searchTerm 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SbusApi
     */
    public getBusinessUnits(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return SbusApiFp(this.configuration).getBusinessUnits(searchTerm, pageable, options).then((request) => request(this.axios, this.basePath));
    }
}

