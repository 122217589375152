/* tslint:disable */
/* eslint-disable */
/**
 * Vista API
 * Open API definition
 *
 * The version of the OpenAPI document: 1.25.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Timeline associated with each stage
 * @export
 * @interface ShipmentStageSegmentLine
 */
export interface ShipmentStageSegmentLine {
    /**
     * Date
     * @type {string}
     * @memberof ShipmentStageSegmentLine
     */
    'date'?: string;
    /**
     * Event source
     * @type {string}
     * @memberof ShipmentStageSegmentLine
     */
    'source'?: string;
    /**
     * Event type
     * @type {string}
     * @memberof ShipmentStageSegmentLine
     */
    'type'?: ShipmentStageSegmentLineTypeEnum;
    /**
     * Event sub type
     * @type {string}
     * @memberof ShipmentStageSegmentLine
     */
    'subType'?: ShipmentStageSegmentLineSubTypeEnum;
    /**
     * Event location
     * @type {string}
     * @memberof ShipmentStageSegmentLine
     */
    'location'?: string;
    /**
     * The time the event was transmitted
     * @type {string}
     * @memberof ShipmentStageSegmentLine
     */
    'eventTransmissionTime'?: string;
}

export const ShipmentStageSegmentLineTypeEnum = {
    Eta: 'ETA',
    Ata: 'ATA',
    Etd: 'ETD',
    Atd: 'ATD',
    Other: 'OTHER',
    TechnicalClosed: 'TECHNICAL_CLOSED'
} as const;

export type ShipmentStageSegmentLineTypeEnum = typeof ShipmentStageSegmentLineTypeEnum[keyof typeof ShipmentStageSegmentLineTypeEnum];
export const ShipmentStageSegmentLineSubTypeEnum = {
    UnloadedFromRail: 'UNLOADED_FROM_RAIL',
    LoadedOnRail: 'LOADED_ON_RAIL',
    DepartureFromRailwayStation: 'DEPARTURE_FROM_RAILWAY_STATION',
    LoadedOnVessel: 'LOADED_ON_VESSEL',
    GateInTerminal: 'GATE_IN_TERMINAL',
    GateOutTerminal: 'GATE_OUT_TERMINAL',
    UnloadedFromVessel: 'UNLOADED_FROM_VESSEL',
    EnRouteToPickupLocation: 'EN_ROUTE_TO_PICKUP_LOCATION',
    ArrivedAtPickupLocation: 'ARRIVED_AT_PICKUP_LOCATION',
    CollectionPickupCompleted: 'COLLECTION_PICKUP_COMPLETED',
    DepartedPickupLocation: 'DEPARTED_PICKUP_LOCATION',
    EnRouteToDeliveryLocation: 'EN_ROUTE_TO_DELIVERY_LOCATION',
    EnRoute: 'EN_ROUTE',
    ShipmentDelayed: 'SHIPMENT_DELAYED',
    DelayedInTransit: 'DELAYED_IN_TRANSIT',
    EtaDeliveryAtRisk: 'ETA_DELIVERY_AT_RISK',
    EtaDelivery: 'ETA_DELIVERY',
    EnRouteToTerminalLocation: 'EN_ROUTE_TO_TERMINAL_LOCATION',
    ReadyForPickupAtArrivalTerminal: 'READY_FOR_PICKUP_AT_ARRIVAL_TERMINAL',
    PickedUpAtArrivalTerminal: 'PICKED_UP_AT_ARRIVAL_TERMINAL',
    DroppedAtDepartureTerminal: 'DROPPED_AT_DEPARTURE_TERMINAL',
    DepartedFromDepartureTerminal: 'DEPARTED_FROM_DEPARTURE_TERMINAL',
    ArrivedAtDeliveryLocation: 'ARRIVED_AT_DELIVERY_LOCATION',
    DepartedDeliveryLocation: 'DEPARTED_DELIVERY_LOCATION',
    DeliveryCompleted: 'DELIVERY_COMPLETED',
    DeliveryNotCompleted: 'DELIVERY_NOT_COMPLETED',
    DeliveredIncorrectly: 'DELIVERED_INCORRECTLY',
    LatestAppointmentChange: 'LATEST_APPOINTMENT_CHANGE',
    CompletedLoadingAtPickupLocation: 'COMPLETED_LOADING_AT_PICKUP_LOCATION',
    EstimatedDateAndOrTimeOfArrivalAtConsignee: 'ESTIMATED_DATE_AND_OR_TIME_OF_ARRIVAL_AT_CONSIGNEE',
    CompletedUnloadingAtDeliveryLocation: 'COMPLETED_UNLOADING_AT_DELIVERY_LOCATION',
    CarrierDepartedDeliveryLocation: 'CARRIER_DEPARTED_DELIVERY_LOCATION',
    CarrierDepartedPickUpLocation: 'CARRIER_DEPARTED_PICK_UP_LOCATION'
} as const;

export type ShipmentStageSegmentLineSubTypeEnum = typeof ShipmentStageSegmentLineSubTypeEnum[keyof typeof ShipmentStageSegmentLineSubTypeEnum];


