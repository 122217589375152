/* tslint:disable */
/* eslint-disable */
/**
 * Vista API
 * Open API definition
 *
 * The version of the OpenAPI document: 1.25.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { DeliveryDestination } from './delivery-destination';
// May contain unused imports in some cases
// @ts-ignore
import { DeliveryDetailItem } from './delivery-detail-item';
// May contain unused imports in some cases
// @ts-ignore
import { DeliveryOrigin } from './delivery-origin';
// May contain unused imports in some cases
// @ts-ignore
import { DeliveryPartner } from './delivery-partner';
// May contain unused imports in some cases
// @ts-ignore
import { Incoterm } from './incoterm';
// May contain unused imports in some cases
// @ts-ignore
import { PurchaseOrderPartner } from './purchase-order-partner';
// May contain unused imports in some cases
// @ts-ignore
import { SalesOrderPartner } from './sales-order-partner';
// May contain unused imports in some cases
// @ts-ignore
import { ShipmentPartner } from './shipment-partner';
// May contain unused imports in some cases
// @ts-ignore
import { Vessel } from './vessel';
// May contain unused imports in some cases
// @ts-ignore
import { Volume } from './volume';
// May contain unused imports in some cases
// @ts-ignore
import { Weight } from './weight';

/**
 * 
 * @export
 * @interface DeliveryDetail
 */
export interface DeliveryDetail {
    /**
     * 
     * @type {DeliveryOrigin}
     * @memberof DeliveryDetail
     */
    'origin'?: DeliveryOrigin;
    /**
     * 
     * @type {DeliveryDestination}
     * @memberof DeliveryDetail
     */
    'destination'?: DeliveryDestination;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDetail
     */
    'deliveryNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDetail
     */
    'salesOrderNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDetail
     */
    'purchaseOrderNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDetail
     */
    'mainlegShipmentNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDetail
     */
    'prelegShipmentNumber'?: string;
    /**
     * Primary mode
     * @type {string}
     * @memberof DeliveryDetail
     */
    'primaryMode'?: DeliveryDetailPrimaryModeEnum;
    /**
     * Secondary mode (special processing indicator)
     * @type {string}
     * @memberof DeliveryDetail
     */
    'secondaryMode'?: string;
    /**
     * Equipment type (transportation group)
     * @type {string}
     * @memberof DeliveryDetail
     */
    'equipmentType'?: string;
    /**
     * 
     * @type {Incoterm}
     * @memberof DeliveryDetail
     */
    'incoterm'?: Incoterm;
    /**
     * Container number
     * @type {string}
     * @memberof DeliveryDetail
     */
    'containerNumber'?: string;
    /**
     * Flight number
     * @type {string}
     * @memberof DeliveryDetail
     */
    'flightNumber'?: string;
    /**
     * House airway bill number
     * @type {string}
     * @memberof DeliveryDetail
     */
    'houseAirwayBill'?: string;
    /**
     * Master airway bill number
     * @type {string}
     * @memberof DeliveryDetail
     */
    'masterAirWaybill'?: string;
    /**
     * Bill of lading
     * @type {string}
     * @memberof DeliveryDetail
     */
    'billOfLadingNumber'?: string;
    /**
     * 
     * @type {Vessel}
     * @memberof DeliveryDetail
     */
    'vesselInfo'?: Vessel;
    /**
     * 
     * @type {Weight}
     * @memberof DeliveryDetail
     */
    'totalGrossWeight'?: Weight;
    /**
     * 
     * @type {Weight}
     * @memberof DeliveryDetail
     */
    'totalNetWeight'?: Weight;
    /**
     * 
     * @type {Volume}
     * @memberof DeliveryDetail
     */
    'totalVolume'?: Volume;
    /**
     * Voyage ID
     * @type {string}
     * @memberof DeliveryDetail
     */
    'voyageId'?: string;
    /**
     * Booking number
     * @type {string}
     * @memberof DeliveryDetail
     */
    'bookingNumber'?: string;
    /**
     * Unloading Point code. Only relevant for SURFACE.
     * @type {string}
     * @memberof DeliveryDetail
     */
    'unloadingPoint'?: string;
    /**
     * Means of Transport ID. Only relevant for SURFACE.
     * @type {string}
     * @memberof DeliveryDetail
     */
    'meansOfTransportId'?: string;
    /**
     * Container-level packaging. Only relevant for SURFACE.
     * @type {string}
     * @memberof DeliveryDetail
     */
    'packagingMaterial'?: string;
    /**
     * Items
     * @type {Array<DeliveryDetailItem>}
     * @memberof DeliveryDetail
     */
    'lineItems'?: Array<DeliveryDetailItem>;
    /**
     * 
     * @type {Array<PurchaseOrderPartner>}
     * @memberof DeliveryDetail
     */
    'purchaseOrderPartners'?: Array<PurchaseOrderPartner>;
    /**
     * 
     * @type {Array<SalesOrderPartner>}
     * @memberof DeliveryDetail
     */
    'salesOrderPartners'?: Array<SalesOrderPartner>;
    /**
     * 
     * @type {Array<DeliveryPartner>}
     * @memberof DeliveryDetail
     */
    'deliveryPartners'?: Array<DeliveryPartner>;
    /**
     * Partners from the preleg shipment
     * @type {Array<ShipmentPartner>}
     * @memberof DeliveryDetail
     */
    'prelegPartners'?: Array<ShipmentPartner>;
    /**
     * Partners from the mainleg or directleg shipment
     * @type {Array<ShipmentPartner>}
     * @memberof DeliveryDetail
     */
    'mainOrDirectlegPartners'?: Array<ShipmentPartner>;
}

export const DeliveryDetailPrimaryModeEnum = {
    Air: 'AIR',
    Sea: 'SEA',
    Surface: 'SURFACE',
    Other: 'OTHER'
} as const;

export type DeliveryDetailPrimaryModeEnum = typeof DeliveryDetailPrimaryModeEnum[keyof typeof DeliveryDetailPrimaryModeEnum];


