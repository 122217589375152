/* tslint:disable */
/* eslint-disable */
/**
 * Vista API
 * Open API definition
 *
 * The version of the OpenAPI document: 1.25.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../../base';
// @ts-ignore
import { ComparisonResult } from '../../model/vista';
// @ts-ignore
import { DeliveryDetail } from '../../model/vista';
// @ts-ignore
import { DeliverySummaries } from '../../model/vista';
// @ts-ignore
import { DeliverySummariesExportRequestBody } from '../../model/vista';
// @ts-ignore
import { DeliverySummaryBody } from '../../model/vista';
// @ts-ignore
import { DeliverySummaryCount } from '../../model/vista';
// @ts-ignore
import { DeliveryTimeline } from '../../model/vista';
// @ts-ignore
import { Pageable } from '../../model/vista';
/**
 * DeliverySummariesApi - axios parameter creator
 * @export
 */
export const DeliverySummariesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the count of delivery summaries
         * @summary Counts the number of delivery summaries
         * @param {DeliverySummaryBody} [deliverySummaryBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countDeliveries: async (deliverySummaryBody?: DeliverySummaryBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deliverysummaries/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deliverySummaryBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a summary of delivery information
         * @summary Returns a list of delivery summaries
         * @param {Pageable} pageable 
         * @param {DeliverySummaryBody} [deliverySummaryBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverySummaries: async (pageable: Pageable, deliverySummaryBody?: DeliverySummaryBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('deliverySummaries', 'pageable', pageable)
            const localVarPath = `/deliverysummaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deliverySummaryBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a summary of delivery information
         * @summary Returns a csv export of delivery summaries
         * @param {Pageable} pageable 
         * @param {number} [page] 
         * @param {DeliverySummariesExportRequestBody} [deliverySummariesExportRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverySummariesExport: async (pageable: Pageable, page?: number, deliverySummariesExportRequestBody?: DeliverySummariesExportRequestBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('deliverySummariesExport', 'pageable', pageable)
            const localVarPath = `/deliverysummaries/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deliverySummariesExportRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the delivery timeline from the database
         * @summary Returns the timeline of a delivery
         * @param {string} id 
         * @param {number} from 
         * @param {number} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDeliveryVistaDifferences: async (id: string, from: number, to: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findDeliveryVistaDifferences', 'id', id)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('findDeliveryVistaDifferences', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('findDeliveryVistaDifferences', 'to', to)
            const localVarPath = `/deliverysummaries/compare/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a delivery detail from the database
         * @summary Returns the detail of a delivery
         * @param {string} deliveryNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSummaryByDeliveryNumber: async (deliveryNumber: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryNumber' is not null or undefined
            assertParamExists('findSummaryByDeliveryNumber', 'deliveryNumber', deliveryNumber)
            const localVarPath = `/deliverysummaries/{deliveryNumber}/info`
                .replace(`{${"deliveryNumber"}}`, encodeURIComponent(String(deliveryNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the delivery timeline from the database
         * @summary Returns the timeline of a delivery
         * @param {string} deliveryNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTimelineByDeliveryNumber: async (deliveryNumber: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryNumber' is not null or undefined
            assertParamExists('findTimelineByDeliveryNumber', 'deliveryNumber', deliveryNumber)
            const localVarPath = `/deliverysummaries/{deliveryNumber}/timeline`
                .replace(`{${"deliveryNumber"}}`, encodeURIComponent(String(deliveryNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliverySummariesApi - functional programming interface
 * @export
 */
export const DeliverySummariesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliverySummariesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the count of delivery summaries
         * @summary Counts the number of delivery summaries
         * @param {DeliverySummaryBody} [deliverySummaryBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countDeliveries(deliverySummaryBody?: DeliverySummaryBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliverySummaryCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countDeliveries(deliverySummaryBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverySummariesApi.countDeliveries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a summary of delivery information
         * @summary Returns a list of delivery summaries
         * @param {Pageable} pageable 
         * @param {DeliverySummaryBody} [deliverySummaryBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverySummaries(pageable: Pageable, deliverySummaryBody?: DeliverySummaryBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliverySummaries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverySummaries(pageable, deliverySummaryBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverySummariesApi.deliverySummaries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a summary of delivery information
         * @summary Returns a csv export of delivery summaries
         * @param {Pageable} pageable 
         * @param {number} [page] 
         * @param {DeliverySummariesExportRequestBody} [deliverySummariesExportRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverySummariesExport(pageable: Pageable, page?: number, deliverySummariesExportRequestBody?: DeliverySummariesExportRequestBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverySummariesExport(pageable, page, deliverySummariesExportRequestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverySummariesApi.deliverySummariesExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the delivery timeline from the database
         * @summary Returns the timeline of a delivery
         * @param {string} id 
         * @param {number} from 
         * @param {number} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findDeliveryVistaDifferences(id: string, from: number, to: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparisonResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findDeliveryVistaDifferences(id, from, to, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverySummariesApi.findDeliveryVistaDifferences']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a delivery detail from the database
         * @summary Returns the detail of a delivery
         * @param {string} deliveryNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSummaryByDeliveryNumber(deliveryNumber: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSummaryByDeliveryNumber(deliveryNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverySummariesApi.findSummaryByDeliveryNumber']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the delivery timeline from the database
         * @summary Returns the timeline of a delivery
         * @param {string} deliveryNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTimelineByDeliveryNumber(deliveryNumber: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryTimeline>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTimelineByDeliveryNumber(deliveryNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverySummariesApi.findTimelineByDeliveryNumber']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DeliverySummariesApi - factory interface
 * @export
 */
export const DeliverySummariesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliverySummariesApiFp(configuration)
    return {
        /**
         * Returns the count of delivery summaries
         * @summary Counts the number of delivery summaries
         * @param {DeliverySummaryBody} [deliverySummaryBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countDeliveries(deliverySummaryBody?: DeliverySummaryBody, options?: any): AxiosPromise<DeliverySummaryCount> {
            return localVarFp.countDeliveries(deliverySummaryBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a summary of delivery information
         * @summary Returns a list of delivery summaries
         * @param {Pageable} pageable 
         * @param {DeliverySummaryBody} [deliverySummaryBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverySummaries(pageable: Pageable, deliverySummaryBody?: DeliverySummaryBody, options?: any): AxiosPromise<DeliverySummaries> {
            return localVarFp.deliverySummaries(pageable, deliverySummaryBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a summary of delivery information
         * @summary Returns a csv export of delivery summaries
         * @param {Pageable} pageable 
         * @param {number} [page] 
         * @param {DeliverySummariesExportRequestBody} [deliverySummariesExportRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverySummariesExport(pageable: Pageable, page?: number, deliverySummariesExportRequestBody?: DeliverySummariesExportRequestBody, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.deliverySummariesExport(pageable, page, deliverySummariesExportRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the delivery timeline from the database
         * @summary Returns the timeline of a delivery
         * @param {string} id 
         * @param {number} from 
         * @param {number} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDeliveryVistaDifferences(id: string, from: number, to: number, options?: any): AxiosPromise<ComparisonResult> {
            return localVarFp.findDeliveryVistaDifferences(id, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a delivery detail from the database
         * @summary Returns the detail of a delivery
         * @param {string} deliveryNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSummaryByDeliveryNumber(deliveryNumber: string, options?: any): AxiosPromise<DeliveryDetail> {
            return localVarFp.findSummaryByDeliveryNumber(deliveryNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the delivery timeline from the database
         * @summary Returns the timeline of a delivery
         * @param {string} deliveryNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTimelineByDeliveryNumber(deliveryNumber: string, options?: any): AxiosPromise<DeliveryTimeline> {
            return localVarFp.findTimelineByDeliveryNumber(deliveryNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeliverySummariesApi - object-oriented interface
 * @export
 * @class DeliverySummariesApi
 * @extends {BaseAPI}
 */
export class DeliverySummariesApi extends BaseAPI {
    /**
     * Returns the count of delivery summaries
     * @summary Counts the number of delivery summaries
     * @param {DeliverySummaryBody} [deliverySummaryBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverySummariesApi
     */
    public countDeliveries(deliverySummaryBody?: DeliverySummaryBody, options?: RawAxiosRequestConfig) {
        return DeliverySummariesApiFp(this.configuration).countDeliveries(deliverySummaryBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a summary of delivery information
     * @summary Returns a list of delivery summaries
     * @param {Pageable} pageable 
     * @param {DeliverySummaryBody} [deliverySummaryBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverySummariesApi
     */
    public deliverySummaries(pageable: Pageable, deliverySummaryBody?: DeliverySummaryBody, options?: RawAxiosRequestConfig) {
        return DeliverySummariesApiFp(this.configuration).deliverySummaries(pageable, deliverySummaryBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a summary of delivery information
     * @summary Returns a csv export of delivery summaries
     * @param {Pageable} pageable 
     * @param {number} [page] 
     * @param {DeliverySummariesExportRequestBody} [deliverySummariesExportRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverySummariesApi
     */
    public deliverySummariesExport(pageable: Pageable, page?: number, deliverySummariesExportRequestBody?: DeliverySummariesExportRequestBody, options?: RawAxiosRequestConfig) {
        return DeliverySummariesApiFp(this.configuration).deliverySummariesExport(pageable, page, deliverySummariesExportRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the delivery timeline from the database
     * @summary Returns the timeline of a delivery
     * @param {string} id 
     * @param {number} from 
     * @param {number} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverySummariesApi
     */
    public findDeliveryVistaDifferences(id: string, from: number, to: number, options?: RawAxiosRequestConfig) {
        return DeliverySummariesApiFp(this.configuration).findDeliveryVistaDifferences(id, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a delivery detail from the database
     * @summary Returns the detail of a delivery
     * @param {string} deliveryNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverySummariesApi
     */
    public findSummaryByDeliveryNumber(deliveryNumber: string, options?: RawAxiosRequestConfig) {
        return DeliverySummariesApiFp(this.configuration).findSummaryByDeliveryNumber(deliveryNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the delivery timeline from the database
     * @summary Returns the timeline of a delivery
     * @param {string} deliveryNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverySummariesApi
     */
    public findTimelineByDeliveryNumber(deliveryNumber: string, options?: RawAxiosRequestConfig) {
        return DeliverySummariesApiFp(this.configuration).findTimelineByDeliveryNumber(deliveryNumber, options).then((request) => request(this.axios, this.basePath));
    }
}

