
import { defineComponent } from '@vue/runtime-core';
import Header from '@/components/Header.vue';
import GenericDialog from '@/components/GenericDialog.vue';
import Spinner from '@/components/common/BasfSpinner.vue';
import TheFooter from '@/components/TheFooter.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import { userSession } from '@/typings';
import { useRoute } from 'vue-router';
import LocalConfig from '@/scripts/localConfig';

export default defineComponent({
  data() {
    return {
      authorizing: false,
      loading: false
    };
  },
  components: {
    Header,
    GenericDialog,
    Spinner,
    TheFooter,
    Breadcrumb
  },
  computed: {
    isAuthorized(): boolean {
      return this.$store.state.authorized;
    },
    userData(): any {
      return this.$store.state.userData;
    },
    isLoading(): boolean {
      return this.loading;
    },
    colorTheme(): string {
      return this.$store.state.colorTheme;
    },
    showBreadcrumb(): boolean {
      const route = useRoute();

      return !!route.meta.breadcrumbName;
    }
  },
  methods: {
    initSession() {
      //validate session and check for response, if it's a code request callback then do login, otherwise we wait for user interaction
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params.code) {
        this.loading = true;
        this.getAuthToken(params.code);
      } else if (this.isAuthorized) {
        // if this is a returning user, we re-trigger the login to ensure token is still valid.
        this.getAuthCode();
      } else {
        this.loading = false;
      }
    },
    getAuthCode(): void {
      // redirect to federation service to retrieve auth code
      this.loading = true;
      if (location.hostname === 'localhost') {
        if (LocalConfig.LOCALHOST_TOKEN !== '') {
          this.getAuthToken('local'); // skip call and automatically use manually entered token
        } else {
          window.location.href = process.env.VUE_APP_AUTH_CODE_URL.replace(/redirect_uri=[^&]+/, 'redirect_uri=http://localhost:8080');
        }
      } else {
        window.location.href = process.env.VUE_APP_AUTH_CODE_URL;
      }
    },
    async getAuthToken(code: string) {
      // perform 2nd call to API to get session token
      console.log('Requesting new token');

      await this.$store.dispatch('GET_TOKEN', code).then((response: userSession) => {
        if (!response) {
          this.loginError();
        } else {
          this.loading = false;
          console.log('User authenticated');
          const redirectPath = this.$store.getters.redirectPath || 'main';
          this.$router.push(redirectPath);
          this.$store.commit('clearRedirectPath');
        }
      });
    },
    loginError() {
      this.loading = false;
      this.$store.commit('showDialogAlert', {
        text: 'We could not complete the authentication process, please try again. ',
        header: 'Login error',
        icon: null
      });
    },
    setColorTheme() {
      document.body.className = this.colorTheme;
    }
  },
  mounted() {
    this.initSession();
    this.setColorTheme();
  },
  watch: {
    colorTheme() {
      this.setColorTheme();
    }
  }
});
