import { Configuration } from '@/services/configuration';
import { AuthApi } from '@/services/api/user/auth-api';
import { AlertApi } from '@/services/api/user/alert-api';
import { FilterApi } from '@/services/api/user/filter-api';
import { LogoutApi } from '@/services/api/user/logout-api';
import { TimezoneApi } from '@/services/api/user/timezone-api';
import { UserApi } from '@/services/api/user/user-api';
import { BasfRegionsApi } from '@/services/api/vista/basf-regions-api';
import { ComparisonApi } from '@/services/api/vista/comparison-api';
import { DeliveriesApi } from '@/services/api/vista/deliveries-api';
import { DeliverySummariesApi } from '@/services/api/vista/delivery-summaries-api';
import { DQApi } from '@/services/api/vista/dqapi';
import { FiltersApi } from '@/services/api/vista/filters-api';
import { HomeApi } from '@/services/api/vista/home-api';
import { PartnersApi } from '@/services/api/vista/partners-api';
import { ProductsApi } from '@/services/api/vista/products-api';
import { PurchaseOrdersApi } from '@/services/api/vista/purchase-orders-api';
import { ReferencesApi } from '@/services/api/vista/references-api';
import { SalesOrdersApi } from '@/services/api/vista/sales-orders-api';
import { SbusApi } from '@/services/api/vista/sbus-api';
import { SearchApi } from '@/services/api/vista/search-api';
import { ShipmentsApi } from '@/services/api/vista/shipments-api';

const configuration: Configuration = new Configuration();

//User Services
export const authApi: AuthApi = new AuthApi(configuration);
export const alertApi: AlertApi = new AlertApi(configuration);
export const filterApi: FilterApi = new FilterApi(configuration);
export const timezoneApi: TimezoneApi = new TimezoneApi(configuration);
export const userApi: UserApi = new UserApi(configuration);
export const userLogoutApi = new LogoutApi(configuration);

//Vista Services
export const basfRegionApi: BasfRegionsApi = new BasfRegionsApi(configuration);
export const comparisonApi: ComparisonApi = new ComparisonApi(configuration);
export const deliveriesApi: DeliveriesApi = new DeliveriesApi(configuration);
export const deliverySummariesApi: DeliverySummariesApi = new DeliverySummariesApi(configuration);
export const dqApi: DQApi = new DQApi(configuration);
export const vistaFiltersApi: FiltersApi = new FiltersApi(configuration);
export const homeApi: HomeApi = new HomeApi(configuration);
export const partnersApi: PartnersApi = new PartnersApi(configuration);
export const productsApi: ProductsApi = new ProductsApi(configuration);
export const purchaseOrdersApi: PurchaseOrdersApi = new PurchaseOrdersApi(configuration);
export const referencesApi: ReferencesApi = new ReferencesApi(configuration);
export const salesOrdersApi: SalesOrdersApi = new SalesOrdersApi(configuration);
export const sBusApi: SbusApi = new SbusApi(configuration);
export const searchApi: SearchApi = new SearchApi(configuration);
export const shipmentsApi: ShipmentsApi = new ShipmentsApi(configuration);
